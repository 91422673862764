import React from "react";
import "../styles/Modal.css";  // モーダル用のスタイル

const Modal = ({ show, handleClose, children, terms }) => {
  return (
    <div className={`modal ${show ? "display-block" : "display-none"}`}>
      <div className="modal-content">
        <span className="close-btn" onClick={handleClose}>&times;</span>
        {children}
        {terms && <div className="terms">{terms}</div>} {/* 利用規約を表示 */}
      </div>
    </div>
  );
};

export default Modal;
