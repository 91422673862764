import { Link } from 'react-router-dom';

export default function RefundPolicy() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">返金ポリシー</h1>
      <div className="mb-6 border rounded-lg p-4 shadow text-left">
        <p className="mb-4">
          <Link to="/policy" className="text-blue-500 underline">
            返金ポリシー
          </Link>
        </p>
      </div>

      <div className="mb-6 border rounded-lg p-4 shadow text-left">
        <h2 className="text-xl font-bold mb-2">個人情報保護方針</h2>
        <p className="mb-4">
          <Link to="/privacypolicy" className="text-blue-500 underline">個人情報保護方針</Link>
        </p>
        <h2 className="text-xl font-bold mb-2 whitespace-nowrap">反社会的勢力の排除に関する基本方針</h2>
        <p className="mb-4">
          <Link to="/antisocialexclusionpolicy" className="text-blue-500 underline">反社会的勢力の排除に関する基本方針</Link>
        </p>
      </div>
    </div>
  );
}
