import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EmailForm = () => {
  const [recipients, setRecipients] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [specificUsernames, setSpecificUsernames] = useState('');
  const [allUsernames, setAllUsernames] = useState([]);

  useEffect(() => {
    const fetchUsernames = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(
          'https://us-central1-gggrace-gamer.cloudfunctions.net/api/users',
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setAllUsernames(response.data.map(user => user.username));
      } catch (error) {
        console.error('ユーザー名の取得に失敗しました:', error);
      }
    };
    fetchUsernames();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/send-email',
        { recipients, subject, message, specificUsernames: specificUsernames.split(',').map(u => u.trim()) },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('メールが正常に送信されました');
      setRecipients([]);
      setSubject('');
      setMessage('');
      setSpecificUsernames('');
    } catch (error) {
      console.error('メール送信中にエラーが発生しました:', error);
      alert('メールの送信に失敗しました');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>メール送信</h2>
      <div>
        <label>
          受信者（ロール）:
          <select
            multiple
            value={recipients}
            onChange={(e) => setRecipients(Array.from(e.target.selectedOptions, option => option.value))}
          >
            <option value="user">一般ユーザー</option>
            <option value="ApplicantCast">申請中キャスト</option>
            <option value="IntervieweeCast">面接予定キャスト</option>
            <option value="Cast">キャスト</option>
          </select>
        </label>
      </div>
      <div>
        <label>
          特定のユーザー名（カンマ区切り）:
          <input
            type="text"
            value={specificUsernames}
            onChange={(e) => setSpecificUsernames(e.target.value)}
            list="usernames"
          />
          <datalist id="usernames">
            {allUsernames.map((username, index) => (
              <option key={index} value={username} />
            ))}
          </datalist>
        </label>
      </div>
      <div>
        <label>
          件名:
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </label>
      </div>
      <div>
        <label>
          本文:
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </label>
      </div>
      <button type="submit">送信</button>
    </form>
  );
};

export default EmailForm;