import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField, Button, Typography, Container, Box } from '@mui/material';

const Booking = () => {
  const [castId, setCastId] = useState(null);
  const [bookingDetails, setBookingDetails] = useState({
    date: '',
    time: '',
    plan: ''
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setCastId(params.get('castId'));
  }, [location]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookingDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Booking submitted:', { castId, ...bookingDetails });
    navigate('/booking-confirmation');
  };

  return (
    <Container>
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 700, color: '#5D576B' }}>
          予約
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              id="date"
              name="date"
              type="date"
              label="日付"
              value={bookingDetails.date}
              onChange={handleInputChange}
              required
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              id="time"
              name="time"
              type="time"
              label="時間"
              value={bookingDetails.time}
              onChange={handleInputChange}
              required
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              id="plan"
              name="plan"
              select
              label="プラン"
              value={bookingDetails.plan}
              onChange={handleInputChange}
              SelectProps={{
                native: true,
              }}
              required
            >
              <option value="">選択してください</option>
              <option value="standard">スタンダード</option>
              <option value="premium">プレミアム</option>
            </TextField>
          </Box>
          <Button variant="contained" type="submit" sx={{ mt: 2, px: 4 }}>
            予約する
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Booking;
