import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
  register: () => {},
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserData = async (token) => {
    try {
      await axios.get(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/verify-token',
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      const response = await axios.get(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/user',
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      if (!response.data) {
        throw new Error('ユーザーデータが取得できませんでした');
      }
      
      console.log('User data received:', response.data);
      setUser(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      localStorage.removeItem('token');
      setUser(null);
      throw error;
    }
  };

  useEffect(() => {
    const initializeUser = async () => {
      const token = localStorage.getItem('token');
      console.log('Initializing UserContext, token:', token);
      
      if (token) {
        try {
          await fetchUserData(token);
        } catch (error) {
          console.error('Failed to initialize user:', error);
          localStorage.removeItem('token');
        }
      }
      setIsLoading(false);
    };

    initializeUser();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/login',
        { email, password }
      );
      
      const { idToken } = response.data;
      if (!idToken) {
        throw new Error('認証トークンが取得できませんでした');
      }
      
      localStorage.setItem('token', idToken);
      const userData = await fetchUserData(idToken);
      return userData;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const register = async (email, password, username) => {
    try {
      const response = await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/register',
        { email, password, username }
      );
      const { idToken } = response.data;
      if (!idToken) {
        throw new Error('認証トークンが取得できませんでした');
      }
      localStorage.setItem('token', idToken);
      await fetchUserData(idToken);
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response?.status === 401) {
          localStorage.removeItem('token');
          setUser(null);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <UserContext.Provider value={{ user, login, logout, register, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};