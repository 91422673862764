// ggg/src/components/Home.js
import React from 'react';
import '../styles/Home.css'; // CSSファイルをインポート
import image1 from '../assets/homeimage/image1.png';
import image2 from '../assets/homeimage/image2.png'; // image2をインポート

const Home = () => {
  return (
    <div className="wrapper">
      <div className="items">
        <div className="item" tabIndex="0" style={{ backgroundImage: `url(${image1})` }}></div>
        <div className="item" tabIndex="0" style={{ backgroundImage: `url(${image2})` }}></div> {/* image2を追加 */}
        <div className="item" tabIndex="0" style={{ backgroundImage: 'url(D:/ggg/ggg/src/assets/homeimage/21_wbsty7.png)' }}></div>
        <div className="item" tabIndex="0" style={{ backgroundImage: 'url(D:/ggg/ggg/src/assets/homeimage/1_zlqnwl.jpg)' }}></div>
        <div className="item" tabIndex="0" style={{ backgroundImage: 'url(D:/ggg/ggg/src/assets/homeimage/16_epnmqm.png)' }}></div>
        <div className="item" tabIndex="0" style={{ backgroundImage: 'url(D:/ggg/ggg/src/assets/homeimage/Screenshot_23_8_nbzwdm.png)' }}></div>
        <div className="item" tabIndex="0" style={{ backgroundImage: 'url(D:/ggg/ggg/src/assets/homeimage/29_jbd4di.jpg)' }}></div>
        <div className="item" tabIndex="0" style={{ backgroundImage: 'url(D:/ggg/ggg/src/assets/homeimage/14_zkwtff.png)' }}></div>
        <div className="item" tabIndex="0" style={{ backgroundImage: 'url(D:/ggg/ggg/src/assets/homeimage/8_ucihr5.png)' }}></div>
        <div className="item" tabIndex="0" style={{ backgroundImage: 'url(D:/ggg/ggg/src/assets/homeimage/18_bguamv.png)' }}></div>
        <div className="item" tabIndex="0" style={{ backgroundImage: 'url(D:/ggg/ggg/src/assets/homeimage/Screenshot_18_4_mscxmr.jpg)' }}></div>
        <div className="item" tabIndex="0" style={{ backgroundImage: 'url(D:/ggg/ggg/src/assets/homeimage/2_zja2bq.png)' }}></div>
        <div className="item" tabIndex="0" style={{ backgroundImage: 'url(D:/ggg/ggg/src/assets/homeimage/11_p1oi8m.png)' }}></div>
      </div>
      <div className="footer-text">
        オンラインだからこそ楽しめる特別な時間。
      </div>
    </div>
  );
};

export default Home;