import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
      <h1 className="text-3xl font-bold mb-6 text-center">個人情報保護方針（プライバシーポリシー）</h1>
      <p>GGGrace合同会社（以下、「当社」といいます。）は、ユーザー様の個人情報の保護に最大限の注意を払っています。以下に、当社が個人情報をどのように取り扱い、保護するかについての方針を定めます。</p>

      <h2 className="text-xl font-bold mb-2">1. 個人情報の定義</h2>
      <p>個人情報とは、氏名、住所、電話番号、メールアドレス、その他個人を特定できる情報を指します。</p>

      <h2 className="text-xl font-bold mb-2">2. 個人情報の取得</h2>
      <p>当社は、以下の方法でユーザー様の個人情報を取得いたします。</p>
      <ul>
        <li>サービスの利用登録時</li>
        <li>オンライン通話サービスの予約時</li>
        <li>お問い合わせ時</li>
      </ul>

      <h2 className="text-xl font-bold mb-2">3. 個人情報の利用目的</h2>
      <p>当社は、取得した個人情報を以下の目的で使用いたします。</p>
      <ul>
        <li>オンライン通話サービスの提供および運営</li>
        <li>サービスに関するご案内やサポート対応</li>
        <li>メールマガジンやキャンペーン情報の配信（ユーザー様が同意した場合）</li>
        <li>利用規約に基づく対応</li>
      </ul>

      <h2 className="text-xl font-bold mb-2">4. 個人情報の第三者提供</h2>
      <p>当社は、以下の場合を除き、ユーザー様の同意なく第三者に個人情報を提供することはありません。</p>
      <ul>
        <li>法令に基づく場合</li>
        <li>ユーザー様の生命、身体、財産の保護のために必要がある場合</li>
        <li>公共の利益を保護するために必要がある場合</li>
      </ul>

      <h2 className="text-xl font-bold mb-2">5. 個人情報の安全管理</h2>
      <p>当社は、個人情報を適切に管理し、不正アクセス、漏洩、改ざん、紛失等を防止するため、必要かつ適切な措置を講じます。</p>

      <h2 className="text-xl font-bold mb-2">6. クッキー（Cookie）の使用について</h2>
      <p>当社のウェブサイトでは、ユーザー様の利便性向上のためにクッキーを使用する場合があります。クッキーの使用により個人が特定される情報が収集されることはありません。</p>

      <h2 className="text-xl font-bold mb-2">7. 個人情報の開示・訂正・削除</h2>
      <p>ユーザー様は、ご自身の個人情報について、開示、訂正、削除を請求することができます。請求を希望される場合は、以下の窓口までご連絡ください。</p>
      <p>お問い合わせ窓口<br />メールアドレス：gggracemanager@gmail.com</p>

      <h2 className="text-xl font-bold mb-2">8. プライバシーポリシーの変更</h2>
      <p>当社は、法令の改正やサービス内容の変更に応じて、本プライバシーポリシーを改定する場合があります。改定後の内容は、当社ウェブサイトに掲載された時点で効力を発揮します。</p>
    </div>
  );
};

export default PrivacyPolicy;
