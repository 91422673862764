import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../UserContext';
import { Button, Typography, Container, Box, Table, TableBody, TableCell, TableHead, TableRow, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, Paper } from '@mui/material';
import { format, addDays, setHours, setMinutes, addMinutes } from 'date-fns';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ScheduleRegistration = () => {
  const { user } = useContext(UserContext);
  const [selectedDates, setSelectedDates] = useState([]);
  const [currentSchedule, setCurrentSchedule] = useState({});
  const [isSelecting, setIsSelecting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [isDeselecting, setIsDeselecting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [bookings, setBookings] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [bookedDates, setBookedDates] = useState([]);
  const navigate = useNavigate();
  const [dragStart, setDragStart] = useState(null);
  const [dragEnd, setDragEnd] = useState(null);
  const [dragSelection, setDragSelection] = useState(new Set());

  useEffect(() => {
    if (user) {
      checkUserRole();
    } else {
      navigate('/login');
    }
  }, [user, navigate]);

  const checkUserRole = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('認証トークンが見つかりません。再度ログインしてください。');
      }

      const response = await axios.get(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/get-user-role',
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.role !== 'Admin' && response.data.role !== 'Manager' && response.data.role !== 'Cast') {
        alert('このページにアクセスする権限がありません。');
        navigate('/'); // ホームページにリダイレクト
      } else {
        fetchCurrentSchedule();
      }
    } catch (error) {
      console.error('ユーザー役割の取得中にエラーが発生しました:', error);
      alert('ユーザー役割の取得に失敗しました: ' + error.message);
      navigate('/');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCurrentSchedule = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('認証トークンが見つかりません。再度ログインしてください。');
      }

      const response = await axios.get(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/get-schedule-and-bookings',
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('取得したスケジュールとブッキング:', response.data);

      setCurrentSchedule(response.data.schedule.timeSlots || {});
      setBookings(response.data.bookings || {});

      const selectedDatesFromDB = [];
      const bookedDatesFromDB = [];

      Object.entries(response.data.schedule.timeSlots || {}).forEach(([date, timeSlots]) => {
        Object.entries(timeSlots).forEach(([time, value]) => {
          if (value === true) {
            selectedDatesFromDB.push(`${date} ${time}`);
          } else if (typeof value === 'string') {
            bookedDatesFromDB.push(`${date} ${time}`);
          }
        });
      });

      console.log('選択された日付:', selectedDatesFromDB);
      console.log('予約された日付:', bookedDatesFromDB);

      setSelectedDates(selectedDatesFromDB);
      setBookedDates(bookedDatesFromDB);
    } catch (error) {
      console.error('スケジュールと予約の取得中にエラーが発生しました:', error);
      alert('スケジュールと予約の取得に失敗しました: ' + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('認証トークンが見つかりません。再度ログインしてください。');
      }

      const updatedSchedule = {};
      const currentDate = new Date();
      for (let i = 0; i < 14; i++) {
        const date = format(addDays(currentDate, i), 'yyyy-MM-dd');
        updatedSchedule[date] = {};
        for (let hour = 0; hour < 24; hour++) {
          for (let minute = 0; minute < 60; minute += 15) {
            const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            const dateTimeString = `${date} ${time}`;
            if (selectedDates.includes(dateTimeString)) {
              updatedSchedule[date][time] = true;
            } else if (bookedDates.includes(dateTimeString)) {
              updatedSchedule[date][time] = currentSchedule[date][time];
            } else {
              updatedSchedule[date][time] = false;
            }
          }
        }
      }

      const response = await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/update-schedule',
        { schedule: updatedSchedule },
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log(response.data.message);
      alert('スケジュールが正常に更新されました');
      fetchCurrentSchedule(); // 更新後のスケジュールを再取得
    } catch (error) {
      console.error('スケジュールの更新中にエラーが発生しました:', error);
      alert('スケジュールの更新に失敗しました: ' + error.message);
    }
  };

  const handleMouseDown = (dateTime) => {
    if (bookedDates.includes(dateTime)) return;
    setIsSelecting(true);
    setDragStart(dateTime);
    setDragEnd(dateTime);
    setIsDeselecting(selectedDates.includes(dateTime));
    setDragSelection(new Set([dateTime]));
  };

  const handleMouseEnter = (dateTime) => {
    if (isSelecting) {
      setDragEnd(dateTime);
      updateDragSelection(dateTime);
    }
  };

  const updateDragSelection = (endDateTime) => {
    const [startDate, startTime] = dragStart.split(' ');
    const [endDate, endTime] = endDateTime.split(' ');
    const start = new Date(`${startDate}T${startTime}`);
    const end = new Date(`${endDate}T${endTime}`);

    const minDate = new Date(Math.min(start, end));
    const maxDate = new Date(Math.max(start, end));

    const newDragSelection = new Set();

    for (let date = new Date(minDate); date <= maxDate; date.setDate(date.getDate() + 1)) {
      const currentDate = format(date, 'yyyy-MM-dd');
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
          const dateTimeString = `${currentDate} ${time}`;
          const currentDateTime = new Date(`${currentDate}T${time}`);

          if (currentDateTime >= minDate && currentDateTime <= maxDate && !bookedDates.includes(dateTimeString)) {
            newDragSelection.add(dateTimeString);
          }
        }
      }
    }

    setDragSelection(newDragSelection);
  };

  const handleMouseUp = () => {
    if (isSelecting && dragStart && dragEnd) {
      const newSelectedDates = new Set(selectedDates);

      dragSelection.forEach(dateTime => {
        if (!bookedDates.includes(dateTime)) {
          if (isDeselecting) {
            newSelectedDates.delete(dateTime);
          } else {
            newSelectedDates.add(dateTime);
          }
        }
      });

      setSelectedDates(Array.from(newSelectedDates));
    }

    setIsSelecting(false);
    setDragStart(null);
    setDragEnd(null);
    setIsDeselecting(false);
    setDragSelection(new Set());
  };

  const toggleDateSelection = (dateTime) => {
    setSelectedDates(prevSelectedDates => {
      const newSelectedDates = new Set(prevSelectedDates);
      if (newSelectedDates.has(dateTime)) {
        newSelectedDates.delete(dateTime);
      } else {
        newSelectedDates.add(dateTime);
      }
      return Array.from(newSelectedDates);
    });
  };

  const handleBookingClick = (dateTime) => {
    const [date, time] = dateTime.split(' ');
    const bookedUserName = currentSchedule[date][time];
    setSelectedBooking({ userName: bookedUserName });
    setOpenDialog(true);
  };

  const handleDateSelection = (dateTime) => {
    setSelectedDates(prevDates => {
      if (prevDates.includes(dateTime)) {
        return prevDates.filter(date => date !== dateTime);
      } else {
        return [...prevDates, dateTime];
      }
    });
  };

  const renderCalendar = () => {
    const now = new Date();
    const startDate = now;
    const days = Array.from({ length: 14 }, (_, i) => addDays(startDate, i));
    const times = Array.from({ length: 96 }, (_, i) => {
      const hour = Math.floor(i / 4);
      const minute = (i % 4) * 15;
      return setMinutes(setHours(new Date(), hour), minute);
    });

    return (
      <Box sx={{ height: '500px', overflow: 'auto' }}>
        <Table stickyHeader className="fixed-header-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '60px', padding: '2px', fontSize: '0.75rem' }}>時間</TableCell>
              {days.map(day => (
                <TableCell key={day} sx={{ padding: '2px', fontSize: '0.75rem' }}>{format(day, 'MM/dd (E)')}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {times.map(time => (
              <TableRow key={format(time, 'HH:mm')}>
                <TableCell sx={{ padding: '2px', fontSize: '0.75rem' }}>{format(time, 'HH:mm')}</TableCell>
                {days.map(day => {
                  const dateTimeString = `${format(day, 'yyyy-MM-dd')} ${format(time, 'HH:mm')}`;
                  const isSelected = selectedDates.includes(dateTimeString);
                  const isBooked = typeof currentSchedule[format(day, 'yyyy-MM-dd')]?.[format(time, 'HH:mm')] === 'string';
                  const wasSelected = currentSchedule[format(day, 'yyyy-MM-dd')]?.[format(time, 'HH:mm')] === true;
                  const isDragging = dragSelection.has(dateTimeString);
                  
                  let cellColor = 'white';
                  if (isBooked) {
                    cellColor = '#ffcccb';
                  } else if (isDragging) {
                    cellColor = isDeselecting ? '#ffb74d' : '#4fc3f7';
                  } else if (isSelected) {
                    cellColor = '#81c784';
                  } else if (wasSelected) {
                    cellColor = '#fff59d';
                  }

                  return (
                    <TableCell
                      key={`${day}-${time}`}
                      sx={{
                        padding: '2px',
                        backgroundColor: cellColor,
                        cursor: isBooked ? 'pointer' : 'default',
                        '&:hover': isBooked ? { backgroundColor: '#ff9999' } : { backgroundColor: '#e8f5e9' },
                        width: '30px',
                        height: '15px',
                      }}
                      onMouseDown={() => !isBooked && handleMouseDown(dateTimeString)}
                      onMouseEnter={() => !isBooked && handleMouseEnter(dateTimeString)}
                      onMouseUp={handleMouseUp}
                      onClick={() => isBooked && handleBookingClick(dateTimeString)}
                    />
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  };

  const renderLegend = () => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2, mb: 2 }}>
      {[
        { color: '#81c784', label: '選択済み' },
        { color: '#fff59d', label: '選択解除' },
        { color: '#ffcccb', label: '予約済み' },
        { color: 'white', label: '未選択', border: '1px solid #ccc' },
        { color: '#4fc3f7', label: 'ドラッグ中（選択）' },
        { color: '#ffb74d', label: 'ドラッグ中（解除）' },
      ].map(({ color, label, border }) => (
        <Box key={label} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: 16, height: 16, backgroundColor: color, border, mr: 1 }} />
          <Typography variant="caption">{label}</Typography>
        </Box>
      ))}
    </Box>
  );

  const renderBookingInfo = () => (
    <Box>
      <Typography variant="subtitle1" gutterBottom>
        予約状況
      </Typography>
      {Object.entries(bookings).length > 0 ? (
        Object.entries(bookings).map(([date, bookingsForDate]) => (
          <Box key={date} mb={2}>
            <Typography variant="subtitle2">{date}</Typography>
            {Object.entries(bookingsForDate).map(([time, booking]) => (
              <Box key={`${date}-${time}`} ml={2}>
                <Typography variant="body2">
                  {time}: {booking.userName}
                </Typography>
              </Box>
            ))}
          </Box>
        ))
      ) : (
        <Typography variant="body2">予約はありません</Typography>
      )}
    </Box>
  );

  if (isLoading) {
    return <div>読み込み中...</div>;
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        スケジュール登録
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            {renderLegend()}
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <form onSubmit={handleSubmit}>
              <Box sx={{ height: '70vh', overflow: 'auto' }}>
                {renderCalendar()}
              </Box>
              <Button variant="contained" type="submit" sx={{ mt: 2, px: 4 }}>
                更新
              </Button>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, height: '100%', overflow: 'auto' }}>
            {renderBookingInfo()}
          </Paper>
        </Grid>
      </Grid>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>予約情報</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedBooking && `ユーザー名: ${selectedBooking.userName}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ScheduleRegistration;