import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../UserContext';
import { Container, Typography, TextField, Button, Box, Grid, IconButton, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import imageCompression from 'browser-image-compression';

const ImageItem = ({ id, url, index, moveImage, handleImageDelete }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'image',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveImage(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'image',
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Grid item xs={4} ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Box position="relative">
        <img src={url} alt={`プレビュー ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
        <IconButton
          aria-label="delete"
          onClick={() => handleImageDelete(index)}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
            },
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Grid>
  );
};

const CastProfileEdit = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [formData, setFormData] = useState({
    name: '',
    birthDate: '',
    favoriteGame: '',
    specialty: '',
    selfIntroduction: '',
    images: [],
    imagesBase64: [],
    imageOrder: [],
    audio: null,
    audioBase64: '',
    deletedImages: [],
  });
  const [previewUrls, setPreviewUrls] = useState([]);
  const [audioPreviewUrl, setAudioPreviewUrl] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('ログインが必要です。');
        navigate('/login');
        return;
      }

      if (!user) {
        try {
          const response = await axios.get(
            'https://us-central1-gggrace-gamer.cloudfunctions.net/api/user',
            {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          );
          // ここでUserContextのsetUser関数を呼び出す必要があります
        } catch (error) {
          console.error('認証エラー:', error);
          alert('セッションが切れました。再度ログインしてください。');
          localStorage.removeItem('token');
          navigate('/login');
        }
      }
    };

    checkAuth();
    if (user && user.role === 'Cast') {
      fetchCastProfile();
    }
  }, [navigate, user]);

  const fetchCastProfile = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/cast-profile',
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      if (response.data && Object.keys(response.data).length > 0) {
        console.log('Fetched profile data:', response.data); // デバッグ用ログ
        const imageUrls = response.data.imageUrls || [];
        setFormData(prevData => ({
          ...prevData,
          ...response.data,
          images: imageUrls,
          imagesBase64: imageUrls,
          imageOrder: imageUrls.map((_, index) => index),
        }));
        if (imageUrls.length > 0) {
          setPreviewUrls(imageUrls);
        }
        if (response.data.audioUrl) {
          setAudioPreviewUrl(response.data.audioUrl);
        }
      } else {
        console.log('プロフィールが存在しません。新規作成モードです。');
        setFormData({
          name: '',
          birthDate: '',
          favoriteGame: '',
          specialty: '',
          selfIntroduction: '',
          images: [],
          imagesBase64: [],
          imageOrder: [],
          audio: null,
          audioBase64: '',
          deletedImages: [],
        });
      }
    } catch (error) {
      console.error('プロフィール取得エラー:', error);
      alert('プロフィールの取得に失敗しました。');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const compressImage = async (file) => {
    console.log('圧縮前のファイルサイズ:', file.size / 1024 / 1024, 'MB');
    const options = {
      maxSizeMB: 0.25,
      maxWidthOrHeight: 720,
      useWebWorker: true
    };
    
    try {
      const compressedFile = await imageCompression(file, options);
      console.log('圧縮後のファイルサイズ:', compressedFile.size / 1024 / 1024, 'MB');
      return compressedFile;
    } catch (error) {
      console.error('画像の圧縮に失敗しました:', error);
      return file;
    }
  };

  const handleImageChange = async (event) => {
    console.log('formData:', formData);
    console.log('event.target.files:', event.target.files);
    const files = Array.from(event.target.files);
    const newImages = [...formData.images];
    const newImagesBase64 = [...formData.imagesBase64];
    const newPreviewUrls = [...previewUrls];

    for (const file of files) {
      if (newImages.length < 5) {
        const compressedFile = await compressImage(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          newImages.push(compressedFile);
          newImagesBase64.push(reader.result);
          newPreviewUrls.push(reader.result);
          setFormData((prevData) => ({
            ...prevData,
            images: newImages,
            imagesBase64: newImagesBase64,
            imageOrder: [...prevData.imageOrder, prevData.imageOrder.length],
          }));
          setPreviewUrls(newPreviewUrls);
        };
        reader.readAsDataURL(compressedFile);
      }
    }
  };

  const handleImageDelete = (index) => {
    setFormData(prevData => {
      const newImageOrder = prevData.imageOrder.filter(i => i !== index).map(i => i > index ? i - 1 : i);
      return {
        ...prevData,
        images: prevData.images.filter((_, i) => i !== index),
        imagesBase64: prevData.imagesBase64.filter((_, i) => i !== index),
        imageOrder: newImageOrder,
        deletedImages: [...(prevData.deletedImages || []), prevData.imagesBase64[index]],
      };
    });
    setPreviewUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
  };

  const handleAudioDelete = () => {
    setFormData(prevData => ({
      ...prevData,
      audio: null,
      audioBase64: '',
    }));
    setAudioPreviewUrl(null);
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        setAudioBlob(audioBlob);
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioPreviewUrl(audioUrl);

        // Base64エンコーディング
        const reader = new FileReader();
        reader.readAsDataURL(audioBlob);
        reader.onloadend = () => {
          setFormData(prevData => ({
            ...prevData,
            audioBase64: reader.result
          }));
        };
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('録音の開始に失敗しました:', error);
      alert('録音の開始に失敗しました。マイクへのアクセスを許可してください。');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const moveImage = (dragIndex, hoverIndex) => {
    setFormData(prevData => {
      const newImageOrder = [...prevData.imageOrder];
      const [removed] = newImageOrder.splice(dragIndex, 1);
      newImageOrder.splice(hoverIndex, 0, removed);
      return {
        ...prevData,
        imageOrder: newImageOrder,
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('認証トークンが見つかりません。再度ログインしてください。');
        navigate('/login');
        return;
      }

      const dataToSend = {
        name: formData.name,
        birthDate: formData.birthDate,
        favoriteGame: formData.favoriteGame,
        specialty: formData.specialty,
        selfIntroduction: formData.selfIntroduction,
        imagesBase64: formData.imageOrder.map(index => formData.imagesBase64[index]),
        audioBase64: formData.audioBase64 || null,
        deletedImages: formData.deletedImages || [],
        deleteAudio: !formData.audioBase64,
      };

      console.log('Sending data:', JSON.stringify(dataToSend, null, 2));

      const response = await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/update-cast-profile',
        dataToSend,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        alert('プロフィールが正常に更新されました。');
        setFormData(prevData => ({
          ...prevData,
          imagesBase64: response.data.imageUrls || [],
          imageOrder: response.data.imageUrls ? response.data.imageUrls.map((_, index) => index) : [],
          deletedImages: [],
        }));
        setPreviewUrls(response.data.imageUrls || []);
        if (response.data.audioUrl) {
          setAudioPreviewUrl(response.data.audioUrl);
          setFormData(prevData => ({
            ...prevData,
            audioBase64: response.data.audioUrl,
          }));
        } else {
          setAudioPreviewUrl(null);
          setFormData(prevData => ({
            ...prevData,
            audioBase64: '',
          }));
        }
      } else {
        throw new Error('サーバーからエラーレスポンスを受け取りました。');
      }
    } catch (error) {
      console.error('プロフィール更新中にエラーが発生しました:', error);
      alert('プロフィールの更新に失敗しました。');
    }
  };

  if (!user || user.role !== 'Cast') {
    return <p>このページにアクセスする権限がありません。</p>;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Container maxWidth="lg">
        <Typography variant="h4" component="h1" gutterBottom>
          キャストプロフィール編集
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
                <TextField
                  fullWidth
                  label="名前"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="生年月日"
                  name="birthDate"
                  type="date"
                  value={formData.birthDate}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  fullWidth
                  label="好きなゲーム"
                  name="favoriteGame"
                  value={formData.favoriteGame}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="専門"
                  name="specialty"
                  value={formData.specialty}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="自己紹介"
                  name="selfIntroduction"
                  value={formData.selfIntroduction}
                  onChange={handleInputChange}
                  margin="normal"
                  multiline
                  rows={4}
                  required
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
                <Box sx={{ my: 2 }}>
                  <input
                    accept="image/*"
                    id="image-upload"
                    type="file"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                    multiple
                  />
                  <label htmlFor="image-upload">
                    <Button variant="contained" component="span">
                      プロフィール画像をアップロード（最大5枚）
                    </Button>
                  </label>
                </Box>
                {previewUrls.length > 0 && (
                  <Box sx={{ my: 2, maxHeight: 300, overflow: 'auto' }}>
                    <Typography variant="subtitle1">画像プレビュー（ドラッグして並び替え）:</Typography>
                    <Grid container spacing={2}>
                      {formData.imageOrder.map((orderIndex, index) => (
                        <ImageItem
                          key={orderIndex}
                          id={orderIndex}
                          url={previewUrls[orderIndex]}
                          index={index}
                          moveImage={moveImage}
                          handleImageDelete={handleImageDelete}
                        />
                      ))}
                    </Grid>
                  </Box>
                )}
                <Box sx={{ my: 2 }}>
                  {!isRecording ? (
                    <Button variant="contained" onClick={startRecording}>
                      録音開始
                    </Button>
                  ) : (
                    <Button variant="contained" onClick={stopRecording} color="secondary">
                      録音停止
                    </Button>
                  )}
                </Box>
                {audioPreviewUrl && (
                  <Box sx={{ my: 2 }}>
                    <Typography variant="subtitle1">音声プレビュー:</Typography>
                    <Box display="flex" alignItems="center">
                      <audio controls src={audioPreviewUrl}>
                        お使いのブラウザは音声再生をサポートしていません。
                      </audio>
                      <IconButton aria-label="delete" onClick={handleAudioDelete}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            プロフィールを更新
          </Button>
        </form>
      </Container>
    </DndProvider>
  );
};

export default CastProfileEdit;