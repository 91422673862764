import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom';

const CastApplicationList = () => {
  const [castApplications, setCastApplications] = useState([]);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const fetchCastApplications = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('認証トークンが見つかりません。再度ログインしてください。');
      }

      const response = await axios.get(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/cast-applications',
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      setCastApplications(response.data);
    } catch (error) {
      console.error('キャスト申請の取得中にエラーが発生しました:', error);
      let errorMessage = 'キャスト申請の取得に失敗しました。';
      if (error.response) {
        errorMessage += ` サーバーエラー: ${error.response.data.error || error.response.statusText}`;
      } else if (error.request) {
        errorMessage += ' ネットワークエラー: サーバーに接続できませんでした。';
      } else {
        errorMessage += ` エラー: ${error.message}`;
      }
      alert(errorMessage);
    }
  };

  useEffect(() => {
    if (!user || (user.role !== 'Admin' && user.role !== 'Manager')) {
      navigate('/');
      return;
    }

    fetchCastApplications();
  }, [user, navigate]);

  const handleApplicationApproval = async (applicationId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('認証トークンが見つかりません。再度ログインしてください。');
      }

      await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/approve-cast-application',
        { applicationId },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // 申請の承認後、申請一覧を更新する
      fetchCastApplications();
    } catch (error) {
      console.error('キャスト申請の承認中にエラーが発生しました:', error);
      alert('キャスト申請の承認に失敗しました。');
    }
  };

  const handleApplicationRejection = async (applicationId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('認証トークンが見つかりません。再度ログインしてください。');
      }

      await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/reject-cast-application',
        { applicationId },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // 申請の却下後、申請一覧を更新する
      fetchCastApplications();
    } catch (error) {
      console.error('キャスト申請の却下中にエラーが発生しました:', error);
      alert('キャスト申請の却下に失敗しました。');
    }
  };

  return (
    <div>
      <h2>キャスト申請一覧</h2>
      <table>
        <thead>
          <tr>
            <th>氏名</th>
            <th>生年月日</th>
            <th>自己PR</th>
            <th>画像</th>
            <th>音声</th>
            <th>ステータス</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {castApplications.map((application) => (
            <tr key={application.id}>
              <td>{application.name}</td>
              <td>{application.birthDate}</td>
              <td>{application.selfIntroduction}</td>
              <td>
                {application.imageUrl && (
                  <img src={application.imageUrl} alt={application.name} width="100" />
                )}
              </td>
              <td>
                {application.audioUrl && (
                  <audio controls src={application.audioUrl}>
                    お使いのブラウザは音声再生をサポートしていません。
                  </audio>
                )}
              </td>
              <td>{application.status}</td>
              <td>
                {application.status === 'pending' && (
                  <>
                    <button onClick={() => handleApplicationApproval(application.id)}>
                      承認
                    </button>
                    <button onClick={() => handleApplicationRejection(application.id)}>
                      却下
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CastApplicationList;