import React, { useState, useContext } from 'react';
import { UserContext } from '../UserContext';

/**
 * Register コンポーネント
 * @returns {JSX.Element} 登録・ログインフォーム
 */
const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [isLogin, setIsLogin] = useState(true); // ログインか登録かを管理
  const { login, register } = useContext(UserContext);

  /**
   * フォーム送信時の処理
   * @param {Object} event - フォームイベント
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (isLogin) {
        await login(email, password);
        alert('ログインに成功しました。');
      } else {
        await register(email, password, username);
        alert('登録に成功しました。');
      }
    } catch (error) {
      console.error('エラーが発生しました:', error);
      alert(isLogin ? 'ログインに失敗しました。' : '登録に失敗しました。');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          name="email"
          id="email"
          autoComplete="email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
          required
          name="password"
          id="password"
          autoComplete="new-password"
        />
        {!isLogin && (
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter your username"
            required
            name="username"
            id="username"
            autoComplete="username"
          />
        )}
        <button type="submit">{isLogin ? 'Login' : 'Register'}</button>
      </form>
      <button onClick={() => setIsLogin(!isLogin)}>
        {isLogin ? 'Switch to Register' : 'Switch to Login'}
      </button>
    </div>
  );
};

export default Register;