import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../UserContext';
import { Button, Box, Typography } from '@mui/material';

const CastApplicationForm = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [formData, setFormData] = useState({
    name: '',
    birthDate: '',
    selfIntroduction: '',
    image: null,
    imageBase64: '',
    audioBase64: '',
  });
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioPreviewUrl, setAudioPreviewUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('ログインが必要です。');
        navigate('/login');
        return;
      }

      if (!user) {
        try {
          const response = await axios.get(
            'https://us-central1-gggrace-gamer.cloudfunctions.net/api/user',
            {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          );
          // ここでUserContextのsetUser関数を呼び出す必要があります
        } catch (error) {
          console.error('認証エラー:', error);
          alert('セッションが切れました。再度ログインしてください。');
          localStorage.removeItem('token');
          navigate('/login');
        }
      }
    };

    checkAuth();
  }, [navigate, user]);

  if (!user || user.role !== 'user') {
    return <p>キャスト申請はユーザーのみが行えます。</p>;
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          image: file,
          imageBase64: reader.result,
        }));
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        setAudioBlob(audioBlob);
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioPreviewUrl(audioUrl);

        // Base64エンコーディング
        const reader = new FileReader();
        reader.readAsDataURL(audioBlob);
        reader.onloadend = () => {
          setFormData(prevData => ({
            ...prevData,
            audioBase64: reader.result
          }));
        };
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('録音の開始に失敗しました:', error);
      alert('録音の開始に失敗しました。マイクへのアクセスを許可してください。');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('認証トークンが見つかりません。再度ログインしてください。');
        navigate('/login');
        return;
      }
  
      const dataToSend = {
        name: formData.name,
        birthDate: formData.birthDate,
        selfIntroduction: formData.selfIntroduction,
        imageBase64: formData.imageBase64,
        audioBase64: formData.audioBase64,
      };
  
      const response = await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/cast-application',
        dataToSend,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 201) {
        alert('キャスト申請が正常に送信されました。確認メールをお送りしましたので、ご確認ください。');
        setFormData({
          name: '',
          birthDate: '',
          selfIntroduction: '',
          image: null,
          imageBase64: '',
          audioBase64: '',
        });
        setPreviewUrl(null);
        setAudioPreviewUrl(null);
        setAudioBlob(null);
      } else {
        throw new Error('サーバーからエラーレスポンスを受け取りました。');
      }
    } catch (error) {
      console.error('キャスト申請の送信中にエラーが発生しました:', error);
      let errorMessage = 'キャスト申請の送信に失敗しました。';
      if (error.response) {
        errorMessage += ` サーバーエラー: ${error.response.data.error || error.response.statusText}`;
      } else if (error.request) {
        errorMessage += ' ネットワークエラー: サーバーに接続できませんでした。';
      } else {
        errorMessage += ` エラー: ${error.message}`;
      }
      alert(errorMessage);
    }
  };

  return (
    <div>
      <h2>キャスト申請</h2>
      <form onSubmit={handleSubmit}>
        <label>
          氏名:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            autoComplete="name"
          />
        </label>
        <label>
          生年月日:
          <input
            type="date"
            name="birthDate"
            value={formData.birthDate}
            onChange={handleInputChange}
            required
            autoComplete="bday"
          />
        </label>
        <label>
          自己PR:
          <textarea
            name="selfIntroduction"
            value={formData.selfIntroduction}
            onChange={handleInputChange}
            required
            autoComplete="off"
          />
        </label>
        <label>
          画像:
          <input
            type="file"
            name="image"
            onChange={handleImageChange}
            autoComplete="off"
          />
        </label>
        {previewUrl && (
          <div>
            <h3>画像プレビュー:</h3>
            <img src={previewUrl} alt="プレビュー" style={{ maxWidth: '200px', maxHeight: '200px' }} />
          </div>
        )}
        <Box sx={{ my: 2 }}>
          {!isRecording ? (
            <Button variant="contained" onClick={startRecording}>
              録音開始
            </Button>
          ) : (
            <Button variant="contained" onClick={stopRecording} color="secondary">
              録音停止
            </Button>
          )}
        </Box>
        {audioPreviewUrl && (
          <Box sx={{ my: 2 }}>
            <Typography variant="subtitle1">音声プレビュー:</Typography>
            <audio controls src={audioPreviewUrl}>
              お使いのブラウザは音声再生をサポートしていません。
            </audio>
          </Box>
        )}
        <button type="submit">送信</button>
      </form>
    </div>
  );
};

export default CastApplicationForm;