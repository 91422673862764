import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Container, Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { format, addDays, setHours, setMinutes, addMinutes } from 'date-fns';

const InterviewBooking = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableSlots, setAvailableSlots] = useState({});
  const [selectedTimeRange, setSelectedTimeRange] = useState('');
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('認証トークンが見つかりません。');
      }

      const response = await axios.get(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/user-info',
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setUserData(response.data);
      fetchSchedules();
    } catch (error) {
      console.error('ユーザー情報の取得に失敗しました:', error);
      alert('ユーザー情報の取得に失敗しました。再度ログインしてください。');
      navigate('/login');
    }
  };

  const fetchSchedules = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('認証トークンが見つかりません。再度ログインしてください。');
      }

      const response = await axios.get(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/admin-manager-schedules',
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      const availableSlots = processSchedules(response.data);
      setAvailableSlots(availableSlots);
    } catch (error) {
      console.error('スケジュールの取得中にエラーが発生しました:', error);
      alert('スケジュールの取得に失敗しました: ' + error.message);
    }
  };

  const processSchedules = (schedules) => {
    const { adminSchedules, managerSchedules } = schedules;
    const availableSlots = {};
    const now = new Date();
    const endDate = addDays(now, 14);

    for (let date = now; date <= endDate; date = addDays(date, 1)) {
      const dateString = format(date, 'yyyy-MM-dd');
      availableSlots[dateString] = {};

      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          const timeString = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
          const availableAdmins = checkAvailability(adminSchedules, dateString, timeString);
          const availableManagers = checkAvailability(managerSchedules, dateString, timeString);
          if (availableAdmins.length > 0 || availableManagers.length > 0) {
            availableSlots[dateString][timeString] = [...availableAdmins, ...availableManagers];
          }
        }
      }
    }

    return availableSlots;
  };

  const checkAvailability = (schedules, dateString, timeString) => {
    return Object.entries(schedules)
      .filter(([id, schedule]) => {
        const isAvailable = isTimeSlotAvailable(schedule[dateString], timeString);
        return isAvailable;
      })
      .map(([id, schedule]) => ({ id, role: schedule.role }));
  };

  const isTimeSlotAvailable = (daySchedule, timeString) => {
    if (!daySchedule) return false;
    const [hour, minute] = timeString.split(':').map(Number);
    const startTime = new Date();
    startTime.setHours(hour, minute, 0, 0);

    for (let i = 0; i < 3; i++) {
      const checkTime = addMinutes(startTime, i * 15);
      const checkTimeString = format(checkTime, 'HH:mm');
      if (daySchedule[checkTimeString] !== true) {
        return false;
      }
    }

    return true;
  };

  const handleDateSelection = (dateTime) => {
    setSelectedDate(dateTime);
    const [date, time] = dateTime.split(' ');
    const endTime = addMinutes(new Date(`${date}T${time}`), 45);
    setSelectedTimeRange(`${time} - ${format(endTime, 'HH:mm')}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedDate) {
      alert('面談の日時を選択してください。');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('認証トークンが見つかりません。再度ログインしてください。');
      }

      const response = await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/interview-booking',
        { selectedDate },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 201) {
        alert('面談予約が正常に送信されました。');
        navigate('/booking-confirmation');
      } else {
        throw new Error('サーバーからエラーレスポンスを受け取りました。');
      }
    } catch (error) {
      console.error('面談予約の送信中にエラーが発生しました:', error);
      let errorMessage = '面談予約の送信に失敗しました。';
      if (error.response) {
        errorMessage += ` エラー: ${error.response.data.error}`;
        if (error.response.data.details) {
          errorMessage += ` 詳細: ${error.response.data.details}`;
        }
      } else if (error.request) {
        errorMessage += ' サーバーからの応答がありませんでした。';
      } else {
        errorMessage += ` エラー: ${error.message}`;
      }
      alert(errorMessage);
    }
  };

  const renderCalendar = () => {
    const now = new Date();
    const startDate = now;
    const days = Array.from({ length: 14 }, (_, i) => addDays(startDate, i));
    const times = Array.from({ length: 96 }, (_, i) => {
      const hour = Math.floor(i / 4);
      const minute = (i % 4) * 15;
      return setMinutes(setHours(new Date(), hour), minute);
    });

    return (
      <Box sx={{ height: '500px', overflow: 'auto' }}>
        <Table stickyHeader className="fixed-header-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '60px', padding: '2px', fontSize: '0.75rem' }}>時間</TableCell>
              {days.map(day => (
                <TableCell key={day} sx={{ padding: '2px', fontSize: '0.75rem' }}>{format(day, 'MM/dd (E)')}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {times.map(time => (
              <TableRow key={time} sx={{ height: '15px' }}>
                <TableCell sx={{ padding: '1px', fontSize: '0.7rem' }}>{format(time, 'HH:mm')}</TableCell>
                {days.map(day => {
                  const dateTime = `${format(day, 'yyyy-MM-dd')} ${format(time, 'HH:mm')}`;
                  const [date, timeSlot] = dateTime.split(' ');
                  const availableAdminsOrManagers = availableSlots[date]?.[timeSlot] || [];
                  const isAvailable = availableAdminsOrManagers.length > 0;
                  return (
                    <TableCell
                      key={dateTime}
                      sx={{
                        padding: '0px',
                        backgroundColor: dateTime === selectedDate
                          ? '#4caf50'
                          : isAvailable
                          ? '#ffffff'
                          : '#f0f0f0',
                        cursor: isAvailable ? 'pointer' : 'not-allowed',
                        '&:hover': {
                          backgroundColor: isAvailable
                            ? dateTime === selectedDate
                              ? '#45a049'
                              : '#e8f5e9'
                            : '#f0f0f0',
                        },
                        border: '1px solid #e0e0e0',
                      }}
                      onClick={() => isAvailable && handleDateSelection(dateTime)}
                    >
                      {isAvailable && (
                        <Tooltip title={`利用可能: ${availableAdminsOrManagers.map(a => a.role).join(', ')}`}>
                          <span>{availableAdminsOrManagers.length}</span>
                        </Tooltip>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  };

  const renderLegend = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: 20, height: 20, backgroundColor: '#4caf50', mr: 1 }} />
        <Typography variant="caption">選択済み</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: 20, height: 20, backgroundColor: '#ffffff', border: '1px solid #e0e0e0', mr: 1 }} />
        <Typography variant="caption">予約可能</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: 20, height: 20, backgroundColor: '#f0f0f0', mr: 1 }} />
        <Typography variant="caption">予約不可</Typography>
      </Box>
    </Box>
  );

  const renderSelectedTimeRange = () => (
    <Box sx={{ mt: 2, textAlign: 'center' }}>
      <Typography variant="subtitle1">
        {selectedTimeRange ? `選択された時間: ${selectedTimeRange}` : '時間が選択されていません'}
      </Typography>
    </Box>
  );

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          面談予約
        </Typography>
        <form onSubmit={handleSubmit}>
          {renderLegend()}
          {renderCalendar()}
          {renderSelectedTimeRange()}
          <Button variant="contained" type="submit" sx={{ mt: 2, px: 4 }}>
            予約する
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default InterviewBooking;