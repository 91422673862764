import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './styles/global.css';
import App from './App';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDjlzlgZAPGLyOc5V7jZSpT5tjYJBMleOg",
  authDomain: "gggrace-gamer.firebaseapp.com",
  projectId: "gggrace-gamer",
  storageBucket: "gggrace-gamer.appspot.com",
  messagingSenderId: "83820549703",
  appId: "1:83820549703:web:3cb463926e7a01733a0362"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
