import React, { useState } from 'react';
import axios from 'axios';

function VerifyEmail() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [verificationCode, setVerificationCode] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/verify',
        {
          email,
          password,
          username,
          verificationCode,
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error('Error verifying email:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        autoComplete="email"
      />
      <label htmlFor="password">Password:</label>
      <input
        type="password"
        id="password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <label htmlFor="username">Username:</label>
      <input
        type="text"
        id="username"
        name="username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
        autoComplete="username"
      />
      <label htmlFor="verificationCode">Verification Code:</label>
      <input
        type="text"
        id="verificationCode"
        name="verificationCode"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
        required
      />
      <button type="submit">Verify Email</button>
    </form>
  );
}

export default VerifyEmail;
