import React, { useContext, useEffect } from 'react';
import { UserContext } from '../UserContext';

const UserProfile = () => {
  const { user, logout } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      console.log('User data:', user);
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <div>
      <h1>Welcome, {user.username}</h1>
      <p>Email: {user.email}</p>
      <p>Role: {user.role}</p>
      <p>Currency: {user.currency}</p>
      <button onClick={logout}>Logout</button>
    </div>
  );
};

export default UserProfile;
