import React from 'react';

const AntisocialExclusionPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">反社会的勢力の排除に関する基本方針</h1>
      <p>GGGrace合同会社（以下、「当社」といいます。）は、社会的責任を果たし、安全かつ健全な事業運営を行うため、反社会的勢力に対して断固として排除する方針を以下の通り定めます。</p>

      <h2 className="text-xl font-bold mb-2">1. 反社会的勢力の排除</h2>
      <p>当社は、暴力団、暴力団関係者、総会屋、社会運動等標榜ゴロ、特殊知能暴力集団その他これに準ずる者（以下、「反社会的勢力」といいます。）との一切の関係を遮断し、いかなる取引も行いません。</p>

      <h2 className="text-xl font-bold mb-2">2. 組織的対応</h2>
      <p>当社は、反社会的勢力による不当要求等に対しては、組織として対応し、担当者だけに依存せず、全社的に対応いたします。</p>

      <h2 className="text-xl font-bold mb-2">3. 外部専門機関との連携</h2>
      <p>当社は、警察、弁護士などの外部専門機関と緊密に連携し、反社会的勢力による不当要求等に対して、法的対応を含め、断固たる態度で対応します。</p>

      <h2 className="text-xl font-bold mb-2">4. 不当要求への対応</h2>
      <p>当社は、反社会的勢力からの不当要求があった場合、法的に認められる範囲内で断固として拒否し、不当な利益の提供を一切行いません。</p>

      <h2 className="text-xl font-bold mb-2">5. 損害賠償責任の追及</h2>
      <p>当社は、反社会的勢力による不当要求等により当社や取引先が損害を被った場合、法的措置を講じて、損害賠償責任を追及します。</p>

      <h2 className="text-xl font-bold mb-2">6. 取引先の調査</h2>
      <p>当社は、取引先の健全性を確認するため、必要に応じて取引先の調査を行い、反社会的勢力との関わりがないことを確認します。</p>
    </div>
  );
};

export default AntisocialExclusionPolicy;
