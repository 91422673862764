import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserContext';
import axios from 'axios';
import { Navigate, Link } from 'react-router-dom';
import EmailForm from './EmailForm';
import { Container, Grid, Paper, Typography, TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Box, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const roleHierarchy = {
  'Admin': 5,
  'Manager': 4,
  'Cast': 3,
  'IntervieweeCast': 2,
  'ApplicantCast': 1,
  'user': 0
};

const AdminPage = () => {
  const { user } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newRole, setNewRole] = useState('');
  const [newCurrency, setNewCurrency] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthAndFetchUsers = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('トークンがありません');
        return;
      }

      try {
        // トークンの検証
        await axios.get(
          'https://us-central1-gggrace-gamer.cloudfunctions.net/api/verify-token',
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        if (user && (user.role === 'Admin' || user.role === 'Manager')) {
          await fetchUsers();
        }
      } catch (error) {
        console.error('認証エラー:', error);
        localStorage.removeItem('token');
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthAndFetchUsers();
  }, [user]);

  const fetchUsers = async () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await axios.get(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/users',
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      if (!response.data) {
        throw new Error('ユーザーデータの取得に失敗しました');
      }
      
      setUsers(response.data);
    } catch (error) {
      console.error('ユーザー一覧の取得エラー:', error);
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
      }
    }
  };

  const handleRoleChange = async () => {
    const token = localStorage.getItem('token');
    try {
      if (roleHierarchy[newRole] >= roleHierarchy[user.role]) {
        alert('自分の役割と同等以上の役割は割り当てられません。');
        return;
      }
      console.log('ユーザーの役割を変更します:', selectedUser, 'を', newRole, 'に');
      await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/change-role',
        { uid: selectedUser, role: newRole },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      alert('役割が正常に更新されました');
      fetchUsers(); // 更新されたユーザーリストを取得
    } catch (error) {
      console.error('役割の変更中にエラーが発生しました:', error);
      alert('役割の変更に失敗しました');
    }
  };

  const handleCurrencyChange = async () => {
    const token = localStorage.getItem('token');
    try {
      console.log('Changing currency for user:', selectedUser, 'to', newCurrency);
      await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/change-currency',
        { uid: selectedUser, currency: newCurrency },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      alert('Currency updated successfully');
      fetchUsers(); // 更新されたユーザーリストを取得
    } catch (error) {
      console.error('Error changing currency:', error);
      alert('Failed to change currency');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (user.role !== 'Admin' && user.role !== 'Manager') {
    return <p>Access denied. You do not have sufficient permissions to view this page.</p>;
  }

  const filteredUsers = users.filter(u => 
    u.username.toLowerCase().includes(searchQuery.toLowerCase()) &&
    roleHierarchy[u.role] < roleHierarchy[user.role]
  );

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          管理ページ
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              登録ユーザー
            </Typography>
            <TextField
              fullWidth
              label="ユーザー名で検索"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              margin="normal"
            />
            <List sx={{ maxHeight: 300, overflow: 'auto' }}>
              {filteredUsers.map((user) => (
                <ListItem key={user.uid}>
                  <ListItemText
                    primary={user.username}
                    secondary={`通貨: ${user.currency} - 役割: ${user.role}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="edit" onClick={() => setSelectedUser(user.uid)}>
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              ユーザー編集
            </Typography>
            {selectedUser && (
              <>
                <TextField
                  select
                  fullWidth
                  label="新しい役割"
                  value={newRole}
                  onChange={(e) => setNewRole(e.target.value)}
                  margin="normal"
                >
                  <MenuItem value="">役割を選択</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Manager">Manager</MenuItem>
                  <MenuItem value="Cast">Cast</MenuItem>
                  <MenuItem value="IntervieweeCast">IntervieweeCast</MenuItem>
                  <MenuItem value="ApplicantCast">ApplicantCast</MenuItem>
                  <MenuItem value="user">user</MenuItem>
                </TextField>
                <Button onClick={handleRoleChange} variant="contained" sx={{ mt: 2, mr: 2 }}>
                  役割を更新
                </Button>
                <TextField
                  fullWidth
                  type="number"
                  label="新しい通貨量"
                  value={newCurrency}
                  onChange={(e) => setNewCurrency(e.target.value)}
                  margin="normal"
                />
                <Button onClick={handleCurrencyChange} variant="contained" sx={{ mt: 2 }}>
                  通貨量を更新
                </Button>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              メール送信
            </Typography>
            <EmailForm />
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ mt: 4 }}>
        <Button component={Link} to="/cast-applications" variant="contained">
          キャスト申請一覧を表示
        </Button>
      </Box>
    </Container>
  );
};

export default AdminPage;