import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box, CircularProgress } from '@mui/material';

const PurchaseSuccess = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyPurchase = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('認証情報が見つかりません');
        return;
      }

      try {
        // セッションIDを取得
        const searchParams = new URLSearchParams(location.search);
        const sessionId = searchParams.get('session_id');

        if (!sessionId) {
          throw new Error('セッションIDが見つかりません');
        }

        // 購入の検証
        await axios.post(
          'https://us-central1-gggrace-gamer.cloudfunctions.net/api/verify-purchase',
          { sessionId },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        setIsLoading(false);
      } catch (error) {
        console.error('購入検証エラー:', error);
        setError('購入の検証中にエラーが発生しました');
        setIsLoading(false);
      }
    };

    verifyPurchase();
  }, [location.search]);

  if (isLoading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Box textAlign="center" py={4}>
          <Typography variant="h5" color="error" gutterBottom>
            エラーが発生しました
          </Typography>
          <Typography>
            {error}
          </Typography>
          <Typography variant="body2" color="textSecondary" mt={2}>
            サポートにお問い合わせください。
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box textAlign="center" py={4}>
        <Typography variant="h5" color="primary" gutterBottom>
          購入が完了しました
        </Typography>
        <Typography>
          ポイントが付与されました。
        </Typography>
      </Box>
    </Container>
  );
};

export default PurchaseSuccess; 