export default function RefundPolicy() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">返金ポリシー</h1>
      
      <div className="mb-6 border rounded-lg p-4 shadow">
        <h2 className="text-xl font-bold mb-2">ポイントの返金について</h2>
        <p className="mb-4">購入されたポイントは、原則として返金はできません。一度でもポイントを使用された場合、返金は一切承ることができませんので、ご注意ください。</p>
        <p>未使用のポイントに限り、購入日から30日以内に返金申請を行っていただけます。ポイントが一度でも使用された場合、返金の対象外となります。</p>
      </div>

      <div className="mb-6 border rounded-lg p-4 shadow">
        <h2 className="text-xl font-bold mb-2">システムエラーやバグの場合</h2>
        <p>システムエラーやバグによって、ポイントが正常に付与されなかった場合、返金または再付与の手続きを行います。詳細はサポートまでお問い合わせください。</p>
      </div>

      <div className="mb-6 border rounded-lg p-4 shadow">
        <h2 className="text-xl font-bold mb-2">二重課金・重複請求</h2>
        <p>二重課金や重複請求が発生した場合、確認後、重複分の返金を行います。</p>
      </div>

      <div className="mb-6 border rounded-lg p-4 shadow">
        <h2 className="text-xl font-bold mb-2">返金手続きの費用</h2>
        <p>返金が認められた場合、クレジットカード手数料等の返金手続きにかかる費用はお客様のご負担とさせていただきます。</p>
      </div>

      <div className="mb-6 border rounded-lg p-4 shadow">
        <h2 className="text-xl font-bold mb-2">利用規約違反・不正行為</h2>
        <p>利用規約違反や不正行為が確認された場合、返金は一切承りません。</p>
      </div>

      <div className="mb-6 border rounded-lg p-4 shadow text-left">
        <h2 className="text-xl font-bold mb-2">お問い合わせ</h2>
        <p className="mb-4">返金に関するお問い合わせは、サポートページより行ってください。法的な理由により返金が必要な場合を除き、通常の返金は上記条件に基づきます。</p>
      </div>
    </div>
  );
}
