// ... 既存のコード ...
const LegalNotice = () => (
    <div>
        <h2>特定商取引法に基づく表記</h2>
        <p>販売業者の名称: GGGrace合同会社   山下　R賢悟</p>
        <p>運営統括責任者: 山下　R賢悟</p>
        <p>所在地: 〒143-0024 東京都大田区中央4-16-8</p>
        <p>電話番号: 080-9362-8160　受付時間10:00-18:00</p>
        <p>メールアドレス: gggracemanager@gmail.com</p>
        <p>ホームページURL: <a href="https://www.gggrace.jp">https://www.gggrace.jp</a></p>
        <p>追加手数料等の追加料金: サービス利用料以外に顧客が負担する料金はありません。</p>
        <p>引き渡し時期: ポイント購入後、即座にサービスをご利用いただけます。</p>
        <p>受け付け可能な決済手段: クレジットカードクレジットカード（Visa、MasterCard、American Express）</p>
        <p>交換および返品（返金ポリシー）: デジタルサービスの特性上、提供後の返品・返金はお受けしておりません。ただし、サービス提供に不備があった場合は、サポートセンターにご連絡いただいた上で、適切な対応をさせていただきます。</p>
        <p>決済期間: クレジットカード決済の場合は即時処理されます。</p>
        <p>販売価格
            <ul>500円 → 480ポイント
1,000円 → 950ポイント
3,000円 → 3,100ポイント
5,000円 → 5,300ポイント
10,000円 → 10,500ポイント
15,000円 → 16,000ポイント</ul>
        </p>
    </div>
);

export default LegalNotice; // 修正
// ... 既存のコード ...
